import React from 'react';
import type { FC } from 'react';
import { css } from '@emotion/react';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';

const Page: FC = () => (
  <Layout>
    <SEO
      title="腫瘍・がんの診断"
      description="当院では犬と猫に発生するあらゆる腫瘍に対する診断が可能です。また検査の際には動物の負担・ストレスを減らすように心掛けております。"
      keywords="腫瘍・がんの診断"
    />
    <article css={pageBaseStyle}>
      <h1>腫瘍・がんの診断・がん検診 - Clinical examination -</h1>
      <div css={skewBorder}></div>
      <section>
        <h2>実施可能な特殊検査</h2>
        <p>
          当院では犬と猫に発生するあらゆる腫瘍に対する診断が可能です。また検査の際には動物の負担・ストレスを減らすように心掛けております。
        </p>
        <p>
          <ul>
            <li>・細胞診</li>
            <li>・各種　組織生検</li>
            <li>・骨髄吸引検査</li>
            <li>・関節液採取・検査</li>
            <li>・CT・MRI画像読影</li>
          </ul>
          ※CT・MRI撮影は近隣の画像センターで実施可能です。
        </p>
      </section>
      <section>
        <h2>がん検診</h2>
        <p>
          多くの「がん・悪性腫瘍」は
          <span css={emphasis}>進行すると残念ながら治癒することは難しい</span>
          のが現状であり、これはヒトも動物も同じです。さらに我々人間に比べて犬や猫は言葉を発することができず自覚症状をうったえることができないため、人間よりも腫瘍が進行した段階で見つかることが多いです。しかし
          <span css={emphasis}>
            多くの悪性腫瘍は「早期発見・早期治療」ができれば根治、治癒できることも事実
          </span>
          であります。つまり犬や猫の悪性腫瘍・がんの最大の治療は「早期発見・早期治療」であります。当院では腫瘍を「早期発見」するために「
          <span css={emphasis}>がん検診</span>」を実施しております。
        </p>
        <h3>検診内容</h3>
        <p>
          <ul>
            <li>
              ・全身身体検査：腫瘍認定医Ⅰ種による詳細な身体検査。身体検査が最も重要です
            </li>
            <li>・血圧測定</li>
            <li>・直腸検査</li>
            <li>
              ・血液検査：全血球計算、血液化学検査15項目、電解質、CRP　+必要に応じて甲状腺ホルモン値などの外注検査
            </li>
            <li>・X線撮影：胸部、腹部　+必要に応じて頭部、四肢など</li>
            <li>・超音波検査：心臓、腹部全臓器のスクリーニング検査</li>
            <li>・尿検査</li>
            <li>・糞便検査</li>
          </ul>
        </p>
        <h3>予約制</h3>
        <p>
          所要時間　60～90分
          <br />
          費用　25,000円（税別）
          <br />
          ※犬、猫　小型～大型犬など全て一律の検査費用です。
          <br />
          ※細胞診などの特殊検査は別途費用がかかります。
        </p>
      </section>
    </article>
  </Layout>
);
export default Page;

const pageBaseStyle = css`
  padding: 1rem 4rem;

  @media (max-width: 560px) {
    padding: 1rem !important; // TODO: index.scss撲滅 撲滅次第importantを削除する
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: 1.3rem;
  }

  section {
    margin-top: 2rem;
  }

  h1 {
    text-align: center;
    margin-top: 2rem;
  }

  h2 {
    border-left: solid 0.8rem #80d8ff;
    margin-top: 4rem;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    clear: both;
  }

  ul li {
    padding-left: 1em;
    text-indent: -1em;
  }
`;

const skewBorder = css`
  width: 50%;
  height: 0.5rem;
  margin: 1rem auto 0;
  background-color: #80d8ff;
  background-image: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 7px,
    transparent 0,
    transparent 14px
  );

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const emphasis = css`
  font-weight: bold;
  color: #e66a6a;
`;
